/**
 * MAPP 3D Activation UI
 */

// React.JS 17
import React from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

// MUI Layout
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// MUI Inputs
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';

// MUI Navigation
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// MUI Feedback
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';

// MUI Data Display
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';

// MUI Lab
import MuiAlert from '@mui/material/Alert';

// MUI System
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

// Validator
import isAlphanumeric from 'validator/lib/isAlphanumeric';

// Custom CSS
import './css/mapp3d-activation.css';

// Custom COMPONENTS
import SoftwareDownload from './SoftwareDownload';

//////////////////// END IMPORTS  ////////////////////
const Component = React.Component;
const axios = require('axios');

// Create a theme with style breakpoints, these are some generic defaults
const mapp3dTheme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1280,
        },
    },
});


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


class SnackbarProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            open: false
        };
        this.handleClose = this.handleClose.bind(this);
    }

    getChildContext () {
        return {
            snackbar: {
                showMessage: this.showMessage.bind(this)
            }
        };
    }

    showMessage(message, severity, action, handleAction) {
        this.setState({
            "open": true,
            "message": message,
            "severity": severity,
            "action": action,
            "handleAction": handleAction
        });
    }

    handleActionTouchTap() {
        this.handleRequestClose();
        this.state.handleAction();
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false, message: null, severity: "info", action: null, handleAction: null });
    }

    render () {
        return (
            <div style={{ width: 'inherit', height: 'inherit'}} >
            {this.props.children}
            <Snackbar autoHideDuration={4000} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
            open={this.state.open} action={this.state.action} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity={this.state.severity} sx={{ width: '100%' }}>
            {this.state.message}
            </Alert>
            </Snackbar>
            </div>
        );
    }
}

SnackbarProvider.childContextTypes = {
    snackbar: PropTypes.shape({
        showMessage: PropTypes.func
    })
}

const MappAdminBox = styled(Box)(({theme}) => ({
    bgcolor: 'grey.300', padding: "2rem", textAlign: "center", width: "100%"
}));

const MappTabPanel = styled(TabPanel)(({theme}) => ({
    padding: "2rem 0"
}));


class Mapp3dActivation extends Component {
    constructor(props) {
        super(props);
        // Depends on wordpress setting the body class for logged-in users
        if (!window.mapp3d) {
            console.log("MAPP init variable not found");
            // return false;
        }

        this.state = {
            loggedIn: document.body.classList.contains('logged-in'),
            activeTab: 0
        };

        // Set the context of :this: in the focusTab method
        this.focusTab = this.focusTab.bind(this);
    }

    focusTab(event, tabId) {
        this.setState({
            activeTab: tabId
        });
    }

    // Accessibility
    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        return (
            <ThemeProvider theme={mapp3dTheme} >
            <MappAdminBox id="mapp3d-box" >
                <Box>
            <Tabs value={this.state.activeTab || 0} centered onChange={this.focusTab} aria-label="basic tabs example">
            <Tab label="My Software" {...this.a11yProps(0)} />
            <Tab label="My Devices" {...this.a11yProps(1)} />
            </Tabs>
            </Box>
            <MappTabPanel id="mapp3d-download-tab" value={this.state.activeTab} index={0}>
            <SoftwareDownload /> </MappTabPanel>
            <MappTabPanel id="mapp3d-activation-tab" value={this.state.activeTab} index={1}>
            <DevicesInfo /> </MappTabPanel>
            </MappAdminBox>
            </ThemeProvider>
        );
    }
}

// Tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`} {...other} >
        {value === index && ( <Box > {children} </Box> )}
        </div>
    );
}

const DeviceFormControl = styled(FormControl)(({theme}) => ({
    "minWidth" : "400px", 'display' : 'inline-block', "paddingRight":"2rem", verticalAlign: "bottom"
}));

const DeviceTextField = styled(TextField)(({theme}) => ({
    'width':"100%"
}));

// My Devices Tab
class DevicesInfo extends Component {

    render() {
        return (
            <section id="mapp3d-devices">
            <div className="narrow">
            <ol>
            <li>Launch MAPP 3D application.</li>
            <li>Click ACTIVATE MAPP 3D button in Welcome Window.</li>
            <li>Enter a Device Name below, click ADD.</li> </ol>
            <p className="software_instructions center">
            <a href="https://mapp-3d-help.meyersound.com/install/">Complete installation instructions</a>.</p>
            </div>
            <SnackbarProvider><DevicesForm /></SnackbarProvider>
            </section>
        );
    }
}

class DevicesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusCode: null,
            message: "",
            devices: null,
            userFeatures : null,
            new_device_name: "",
            specialChars : false,
            error: false
        };

        this.getUserFeatures = this.getUserFeatures.bind(this);
        this.getRegistrations = this.getRegistrations.bind(this);
        this.showRegistrations = this.showRegistrations.bind(this);
        this.onAjaxFail = this.onAjaxFail.bind(this);
        this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
    }

    componentDidMount() {
        this.getUserFeatures();
        this.getRegistrations();
    }

    getRegistrations() {
        if (!window.mapp3d) return;
        axios.get( window.mapp3d.ajaxUrl + '?action=getRegistrations' ).then((res) => {
            this.setState({
                statusCode: res.data.statusCode,
                message: res.data.message,
                devices: res.data.data
            });
        }).catch(this.onAjaxFail);
    }

    getUserFeatures() {
        if (!window.mapp3d) return;
        axios.get( window.mapp3d.ajaxUrl + '?action=getUserFeatures').then((res) => {
            // console.dir(res);
            if (typeof res.data != 'object') {
                this.setState({
                    statusCode: res.data.statusCode,
                    message: res.data.message,
                    error: "We could not retrieve your account information"
                });
            }
            this.setState({
                userFeatures : res.data
            });
        }).catch(this.onAjaxFail);
    }

    onAjaxFail(error) {
        this.context.snackbar.showMessage(error, 'error');
    }

    handleSubmitAdd = event => {
        event.preventDefault();
        let postData = new URLSearchParams();
        postData.append('device_name', this.state.new_device_name);
        axios.post(window.mapp3d.ajaxUrl + "?action=addDevice", postData)
             .then((res) => {
                 if (res.data.statusCode === 422) {
                     this.setState({
                         error: "We could not register a device with that name"
                     });
                     this.context.snackbar.showMessage(res.data.data.error, "warning");
                 }
                 if (res.data.statusCode === 201) {
                     this.context.snackbar.showMessage(
                         "Added new device " + this.state.new_device_name, 'success');
                     this.setState({new_device_name : ""}, function(){
                         event.target[0].value = "";
                     });
                 }
                 this.getRegistrations();
             }).catch(this.onAddDevicesFail);
    };

    handleSubmitDeactivate = event => {
        event.preventDefault();
        let postData = new URLSearchParams();
        const device_id  = event.target[0].getAttribute('value');
        const device_name = event.target[1].getAttribute('value');
        postData.append('device_id', device_id);
        axios.post(window.mapp3d.ajaxUrl + "?action=deactivateDevice", postData)
             .then((res) => {
                 this.context.snackbar.showMessage(
                     "Deactivated Device: " + device_name, "success");
                 this.getRegistrations();
            }).catch(this.onUpdateDevicesFail);
    };

    handleNewDeviceNameChange = event => {
        // To disable the add button if input blank
        const { name, value } = event.target;
        let button = document.querySelector('button#addDevice');
        const options = {ignore:"()  '\"-_.,"};

        if (!isAlphanumeric(value, 'en-US', options) && value !== "") {
            this.context.snackbar.showMessage(
                "Special characters are not allowed", "warning");
            this.setState({specialChars : true});
        } else {
            this.setState({specialChars : false});
        }
        this.setState({new_device_name: value});
    };

    onAddDevicesFail = response => {
        console.log("called onAddDevicesFail");
        // Set the error
        console.dir(response);
    //     this.setState(state => ({
    //         error: data["error"]
    //     }));
    };

    onCopyToClipboard = e => {
       // console.log(e.target.value);
        e.target.select();
        document.execCommand("copy");
        e.target.focus();
        this.context.snackbar.showMessage(
            "Reservation copied. Return to MAPP 3D and paste from clipboard.", "success");
    };

    showDevicesList() {
        return (
            <List>
            {this.state.devices.length > 0 && this.state.devices.map(
                device => (
                    <ListItem key={device.device_name}>
                        <form onSubmit={this.handleSubmitDeactivate}
                    sx={{alignItems: "center", alignContent: "center", width: '100%'}} noValidate autoComplete="off" >
                    <Grid  >
                    <Grid item>
                        <DeviceFormControl >
                        <Input id="device_id" sx={{visibility:"hidden", display:"none"}} value={device.id} disabled={true} />
                        <DeviceTextField label="Device Name" defaultValue={device.device_name} disabled />
                    </DeviceFormControl>
                    <DeviceFormControl >
                    {device.reservation_code ? (
                        <Tooltip title="Click to copy to clipboard" placement="top" >
                            <DeviceTextField id="reservation_code" label={"Reservation Code"}
                        defaultValue={device.reservation_code} InputProps={{ readOnly: true }}
                        onClick = {document.queryCommandSupported("copy") ? this.onCopyToClipboard : null}
                        disabled={device.reservation_code ? false : true } />
                        </Tooltip>
                    ) : (
                            <DeviceTextField id="reservation_code" label={"Status"} defaultValue={"ACTIVE"} margin="normal"
                        InputProps={{ readOnly: true }} disabled={true} />
                    )}
                    </DeviceFormControl>
                        <Button className="button" variant="contained" color="primary" type="submit" >
                        {device.reservation_code ? "Remove" : "Deactivate"}
                    </Button>
                    </Grid>
                    </Grid>
                    </form>
                    </ListItem>
                )
            )}
            </List>
        );
    }


    addDeviceActive() {
        if (this.state.new_device_name === "") {
            return false;
        }
        return (!this.state.specialChars);
    }

    showAddDevice() {
        return (
            <form  sx={{display: "block", width:"100%"}} onSubmit={this.handleSubmitAdd} noValidate autoComplete="off" >
            <Grid >
            <Grid sx={{"textAlign": "center"}} item> <DeviceFormControl sx={{"margin" : "0 auto"}}>
            <DeviceTextField label={"Enter New Device Name (computer name)"}
            error={this.state.error ? true : false} helperText={ this.state.error ? "Error: " + this.state.error : " " }
            onChange={this.handleNewDeviceNameChange.bind(this)} />
            </DeviceFormControl>
            <Button className="button" id="addDevice" variant="contained" color="primary" type="submit"
            disabled={!this.addDeviceActive()}> Add </Button>
            </Grid>
            </Grid>
            </form>
        );
    }

    // DevicesForm.showRegistrations()
    showRegistrations() {
        if (!this.state.statusCode) {
            return (<LinearProgress />);
        }

        if (this.state.statusCode === 422) {
            const hostname = window.document.domain;
            const contactUrl = "https://" + hostname + "/contact/";
            return(
                <div id="account-error">
                    <h4>MAPP 3D Account Error</h4>
                    <p>Please contact tech support: <a href={contactUrl}>{contactUrl}</a></p>
                </div>
            );
        }
        return (
            <Grid container>
                <Grid item sx={{width: "100%"}}>
                    {this.state.devices && this.showDevicesList()}
                    {this.state.devices && this.state.userFeatures &&
                     this.state.userFeatures.devices > this.state.devices.length &&
                     this.showAddDevice()}
                </Grid>
            </Grid>
        );
    }

    render() {
        return (
            <section>
                {this.showRegistrations()}
            </section>
        );
    }
}

DevicesForm.contextTypes = {
    snackbar: PropTypes.shape({
        showMessage:  PropTypes.func
    })
}

// Initialize
const target = document.querySelector('#mapp3d-activation');
if (target) { ReactDOM.render(<Mapp3dActivation />, target); }

// EOF
